import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { Trans, useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { RefObject } from 'react'
import { getSelectorsByUserAgent } from 'react-device-detect'

import {
  APP_DOWNLOAD_LINKS,
  CDN_URL,
  PLAYSEE_LINKS,
  REDIRECT_APP_LINK
} from 'assets/constant'
import ArrowDownCircle from 'assets/icon/general/arrow_down_rou_s.svg'
import { SectionContent, SectionLink, SectionTitle } from 'component/section'
import { Button } from 'component/ui/button'
import { VideoOnce } from 'component/video-once'

const App = ({
  device,
  landingRef
}: InferGetServerSidePropsType<typeof getServerSideProps> & {
  landingRef: RefObject<HTMLElement>
}) => {
  const { t } = useTranslation()

  return (
    <main className='w-full bg-white text-label-l1'>
      {/* Landing */}
      <section
        ref={landingRef}
        className='h-[200px] homePad:h-[400px] w-full overflow-hidden relative'
      >
        <img
          src={`${CDN_URL}/about/images/about_landing_pic.jpg`}
          alt="Playsee's landing"
          className='absolute top-0 left-0 object-cover w-full h-full'
        />
      </section>
      {/* Section 1 */}
      <section className='py-[48px] px-[24px] homePad:py-[96px] homePad:px-[180px]'>
        <div className='homePad:max-w-[920px] homePad:mx-auto'>
          <SectionTitle className='text-[24px] leading-[30px] font-bold homePad:text-[60px] homePad:leading-[72px] homePad:max-w-[900px]'>
            {t('about_page.section_0.title')}
          </SectionTitle>
          <SectionContent className='mt-[16px] homePad:mt-[48px]'>
            {t('about_page.section_0.description_0')}
            <br />
            <br />
            {t('about_page.section_0.description_1')}
          </SectionContent>
          <SectionLink
            href='#spots'
            className='mt-[16px] homePad:mt-[48px] inline-flex items-center gap-[8px]'
          >
            <ArrowDownCircle />
            <span>{t('about_page.section_0.link')}</span>
          </SectionLink>
        </div>
      </section>
      {/* Section 2 */}
      <section
        id='spots'
        className='flex flex-col scroll-mt-[48px] homePad:scroll-mt-[128px] homePad:flex-row-reverse'
      >
        <div className='bg-[--branding] homePad:shrink homePad:grow'>
          <div className='left-section px-[24px] py-[48px] homePad:p-0 homePad:h-[422px] homePad:max-w-[850px]'>
            <div className='[grid-area:content] min-h-0'>
              <SectionTitle className='text-primary-on_primary'>
                {t('about_page.section_1.title')}
              </SectionTitle>
              <SectionContent className='text-primary-on_primary mt-[16px] homePad:mt-[24px]'>
                {t('about_page.section_1.description')}
              </SectionContent>
              <SectionLink
                href={`${PLAYSEE_LINKS.platform}spot`}
                className='mt-[16px] homePad:mt-[24px] underline text-primary-on_primary'
                title={t('about_page.section_1.link')}
              >
                {t('about_page.section_1.link')}
              </SectionLink>
            </div>
          </div>
        </div>

        <div className='homePad:h-[422px] homePad:max-w-[582px] homePad:shrink-0 homePad:grow-0'>
          <video
            autoPlay
            muted
            loop
            playsInline
            src={`${CDN_URL}/about/videos/about_video_mobile.mp4`}
            className='object-cover w-full h-auto homePad:hidden'
          />
          <video
            autoPlay
            muted
            loop
            playsInline
            src={`${CDN_URL}/about/videos/about_video_desktop.mp4`}
            className='hidden object-cover w-full h-full homePad:block'
          />
        </div>
      </section>
      {/* Section 3 */}
      <section
        id='community'
        className='flex flex-col homePad:flex-row homePad:justify-center homePad:items-stretch'
      >
        <div className='homePad:shrink homePad:grow'>
          <div className='right-section px-[24px] py-[48px] homePad:p-0 homePad:min-w-[425px] homePad:max-w-[850px]'>
            <div className='[grid-area:content] min-h-0'>
              <SectionTitle>{t('about_page.section_2.title')}</SectionTitle>
              <SectionContent className=' mt-[16px] homePad:mt-[24px]'>
                {t('about_page.section_2.description')}
              </SectionContent>
              <SectionLink
                href={`${PLAYSEE_LINKS.platform}community`}
                className='mt-[16px] homePad:mt-[24px] underline'
                title={t('about_page.section_2.link')}
              >
                {t('about_page.section_2.link')}
              </SectionLink>
            </div>
          </div>
        </div>

        <div className='homePad:h-[518px] homePad:max-w-[680px] homePad:shrink homePad:grow-0 flex items-center homePad:pt-[48px] pb-[48px] desktop:shrink-0 homePad:pr-[96px]'>
          <img
            src={`${CDN_URL}/about/images/about_pic_community.png`}
            alt='Communities'
            loading='lazy'
            className='object-contain w-full h-full'
          />
        </div>
      </section>
      {/* Section 4 */}
      <section
        id='Ailex'
        className='flex flex-col homePad:flex-row bg-[#FFB800] homePad:justify-center'
      >
        <div className='overflow-x-hidden homePad:shrink homePad:grow-0 [direction:rtl] desktop:shrink-0'>
          <div className='flex items-center h-[320px] homePad:pl-[180px] homePad:pr-[86px] homePad:w-[584px] homePad:h-[422px]'>
            <VideoOnce
              className='h-[240px] w-[240px] homePad:h-[360px] homePad:w-[360px] mx-auto mask-video'
              video={`${CDN_URL}/about/videos/ailex_video_v2.mp4`}
              firstFrame={`${CDN_URL}/about/images/ailex_video_v2.png`}
              lastFrame={`${CDN_URL}/about/images/ailex_video_keyframe_v2.png`}
            />
          </div>
        </div>
        <div className='homePad:shrink homePad:grow'>
          <div className='p-[24px] pb-[48px] homePad:p-0 homePad:h-[422px] left-section homePad:max-w-[850px]'>
            <div className='[grid-area:content] min-h-0'>
              <SectionTitle>{t('about_page.section_3.title')}</SectionTitle>
              <SectionContent className=' mt-[16px] homePad:mt-[24px]'>
                {t('about_page.section_3.description')}
              </SectionContent>
              <SectionLink
                href={REDIRECT_APP_LINK}
                className='mt-[16px] homePad:mt-[24px] underline'
                target='_blank'
                rel='noreferrer noopener'
                title={t('about_page.section_3.link')}
              >
                {t('about_page.section_3.link')}
              </SectionLink>
            </div>
          </div>
        </div>
      </section>

      <section className='flex flex-col items-center py-[48px] px-[24px] homePad:py-[96px]'>
        <div className='text-center text-md homePad:text-lg homePad:leading-[22px]'>
          <span>
            {t('about_page.contact_0')}
            <br />
            <Trans i18nKey='about_page.contact_1'>
              <a
                href='mailto:press@playsee.co'
                className='font-semibold underline'
                target='_blank'
                rel='noreferrer noopener'
              >
                press@playsee.co
              </a>
            </Trans>
          </span>
          <br />
          <span>
            <Trans i18nKey='about_page.contact_2'>
              <a
                href='mailto:ir@playsee.co'
                className='font-semibold underline'
                target='_blank'
                rel='noreferrer noopener'
              >
                ir@playsee.co
              </a>
            </Trans>
          </span>
        </div>
        {device === 'mobile' || device === 'tablet' ? (
          <Button
            className='mt-[16px] homePad:mt-[24px] bg-label-l1 text-lg'
            $radius='xl'
            $size='lg'
            asChild
          >
            <a href={REDIRECT_APP_LINK}>{t('common.download_app')}</a>
          </Button>
        ) : (
          <div className='flex items-center gap-[8px] mt-[24px] homePad:mt-[48px]'>
            <a
              href={APP_DOWNLOAD_LINKS.android}
              title='Download Playsee for Android'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img
                src={`${CDN_URL}/common/images/google_play_gray.svg`}
                alt='Download Playsee for Android'
                className='w-[128px] h-[37px]'
              />
            </a>
            <a
              href={APP_DOWNLOAD_LINKS.ios}
              title='Download Playsee for iOS'
              target='_blank'
              rel='noreferrer noopener'
            >
              <img
                src={`${CDN_URL}/common/images/app_store_gray.svg`}
                alt='Download Playsee for iOS'
                className='w-[128px] h-[37px]'
              />
            </a>
          </div>
        )}
      </section>
    </main>
  )
}

export const getServerSideProps: GetServerSideProps<{
  device: string
}> = async context => {
  const userAgent = context.req.headers['user-agent'] || ''
  const locale = context.locale
  const result = getSelectorsByUserAgent(userAgent)
  let device = 'desktop'
  if (result.isTablet) device = 'tablet'
  else if (result.isMobile) device = 'mobile'

  return {
    props: {
      device,
      ...(await serverSideTranslations(locale ?? 'en', ['translation']))
    }
  }
}

export default App
