import { useEffect, useRef, useState } from 'react'
import { twMerge as cn } from 'tailwind-merge'

interface VideoOnceProps {
  video: string
  firstFrame: string
  lastFrame: string
  className?: string
}
export const VideoOnce = (props: VideoOnceProps) => {
  const { video, firstFrame, lastFrame } = props
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isEnded, setIsEnded] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handlePlay = () => {
    setIsPlaying(true)
  }

  const handleEnded = () => {
    setIsEnded(true)
  }

  useEffect(() => {
    const videoElement = videoRef.current
    if (!videoElement || isEnded) return

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            void videoElement.play()
          }
        })
      },
      { threshold: 1 }
    )

    observer.observe(videoElement)

    return () => {
      observer.disconnect()
    }
  }, [isEnded])

  return (
    <div className={cn('relative', props.className)}>
      <video
        ref={videoRef}
        src={video}
        className={cn(
          'absolute top-0 left-0 object-contain w-full h-full',
          isEnded && 'opacity-0'
        )}
        autoPlay={false}
        muted
        loop={false}
        playsInline
        preload='auto'
        onPlay={handlePlay}
        onEnded={handleEnded}
      />

      <img
        src={firstFrame}
        className={cn(
          'absolute top-0 left-0 object-contain w-full h-full',
          isPlaying && 'opacity-0'
        )}
        alt='first frame'
      />

      <img
        src={lastFrame}
        className={cn(
          'absolute top-0 left-0 object-contain w-full h-full',
          !isEnded && 'opacity-0'
        )}
        alt='last frame'
      />
    </div>
  )
}
